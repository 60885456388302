/* NewspaperComponent.css */

/*=============================================
=            CSS Variables                    =
=============================================*/
:root {
    /* Colors */
    --primary-color: #308C84;
    --primary-light: #4ECDC4;
    --primary-dark: #256B65;
    --secondary-color: #4ECDC4;
    --text-dark: #2c3e50;
    --text-light: #4a5568;
    --background-light: #f5f7fa;
    --background-white: #ffffff;
    --border-color: #e0e0e0;
    --error-color: #ff6b6b;
    --success-color: #37b679;
    --warning-color: #ffd93d;

    /* Spacing */
    --spacing-xs: 0.25rem;
    --spacing-sm: 0.5rem;
    --spacing-md: 1rem;
    --spacing-lg: 1.5rem;
    --spacing-xl: 2rem;

    /* Border Radius */
    --border-radius-sm: 8px;
    --border-radius-md: 12px;
    --border-radius-lg: 20px;

    /* Shadows */
    --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
    --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
    --shadow-hover: 0 15px 30px rgba(0, 0, 0, 0.15);

    /* Transitions */
    --transition-fast: 150ms ease;
    --transition-normal: 300ms ease;
    --transition-slow: 500ms ease;

    /* Z-index layers */
    --z-index-modal: 1000;
    --z-index-overlay: 900;
    --z-index-dropdown: 800;
    --z-index-header: 700;
}

/*=============================================
=            Global Styles & Resets           =
=============================================*/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background-light);
    color: var(--text-dark);
}

button {
    cursor: pointer;
    font-family: inherit;
}

input {
    font-family: inherit;
}

/*=============================================
=            Container Styles                 =
=============================================*/
.enhanced-newspaper-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: var(--spacing-xl) var(--spacing-md);
    background-color: var(--background-light);
    min-height: 100vh;
    animation: fadeIn var(--transition-normal);
}

@media (max-width: 768px) {
    .enhanced-newspaper-container {
        padding: var(--spacing-md) var(--spacing-sm);
    }
}

/*=============================================
=            Header Styles                    =
=============================================*/
.enhanced-news-header {
    background-color: var(--background-white);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-md);
    margin-bottom: var(--spacing-xl);
    padding: var(--spacing-lg);
    transition: transform var(--transition-normal), 
                box-shadow var(--transition-normal);
}

.enhanced-news-header:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-lg);
}

.page-title {
    font-size: 2rem;
    color: var(--primary-color);
    text-align: center;
    margin-bottom: var(--spacing-lg);
    position: relative;
    padding-bottom: var(--spacing-sm);
}

.page-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background-color: var(--primary-color);
    border-radius: 3px;
}

/*=============================================
=            Search and Filter Styles         =
=============================================*/
.search-filter-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    align-items: center;
}

.search-input-wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    max-width: 600px;
    transition: transform var(--transition-normal);
}

.search-input-wrapper:focus-within {
    transform: scale(1.01);
}

.search-input {
    width: 100%;
    padding: 0.75rem 3rem 0.75rem 1rem;
    border: 2px solid var(--border-color);
    border-radius: var(--border-radius-md);
    font-size: 1rem;
    transition: all var(--transition-normal);
    background-color: var(--background-white);
}

.search-input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(48, 140, 132, 0.1);
}

.search-input::placeholder {
    color: var(--text-light);
    opacity: 0.7;
}

.search-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-light);
    transition: color var(--transition-normal);
}

.search-input-wrapper:focus-within .search-icon {
    color: var(--primary-color);
}

/* Continue with Part 2... */
/*=============================================
=            Category Filters                 =
=============================================*/
.category-filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--spacing-sm);
    padding: var(--spacing-md) 0;
    width: 100%;
}

.category-btn {
    padding: 0.5rem 1.25rem;
    border: 2px solid var(--border-color);
    background-color: var(--background-white);
    color: var(--text-dark);
    border-radius: var(--border-radius-lg);
    cursor: pointer;
    transition: all var(--transition-normal);
    font-size: 0.95rem;
    font-weight: 500;
    position: relative;
    overflow: hidden;
}

.category-btn::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background-color: var(--primary-color);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: width 0.4s ease, height 0.4s ease, opacity 0.4s ease;
    z-index: 1;
}

.category-btn:hover::before {
    width: 200%;
    height: 200%;
    opacity: 0.1;
}

.category-btn.active {
    background-color: var(--primary-color);
    color: var(--background-white);
    border-color: var(--primary-color);
    transform: translateY(-1px);
    box-shadow: var(--shadow-sm);
}

.category-btn:hover:not(.active) {
    border-color: var(--primary-color);
    color: var(--primary-color);
    transform: translateY(-1px);
}

/*=============================================
=            Trending Topics                  =
=============================================*/
.trending-topics-section {
    background: var(--background-white);
    padding: var(--spacing-lg);
    margin: var(--spacing-lg) 0;
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-md);
    transition: transform var(--transition-normal);
}

.trending-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-lg);
    padding-bottom: var(--spacing-md);
    border-bottom: 1px solid var(--border-color);
}

.trending-header h2 {
    font-size: 1.5rem;
    color: var(--text-dark);
    margin: 0;
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
}

.trending-refresh button {
    background: none;
    border: none;
    color: var(--text-light);
    cursor: pointer;
    padding: var(--spacing-sm);
    border-radius: 50%;
    transition: all var(--transition-normal);
    display: flex;
    align-items: center;
    justify-content: center;
}

.trending-refresh button:hover {
    background-color: var(--background-light);
    color: var(--primary-color);
    transform: rotate(180deg);
}

.trending-topics-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: var(--spacing-md);
}

.trending-topic-card {
    display: flex;
    align-items: center;
    background: var(--background-white);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-sm);
    box-shadow: var(--shadow-sm);
    transition: all var(--transition-normal);
    border: 1px solid var(--border-color);
}

.trending-topic-card:hover {
    transform: translateY(-3px);
    box-shadow: var(--shadow-lg);
    border-color: var(--primary-light);
}

.topic-rank {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--primary-color);
    margin-right: var(--spacing-md);
    min-width: 30px;
    text-align: center;
    position: relative;
}

.topic-rank::after {
    content: '';
    position: absolute;
    right: -10px;
    top: 50%;
    height: 70%;
    width: 2px;
    background-color: var(--border-color);
    transform: translateY(-50%);
}

.topic-content {
    flex: 1;
}

.topic-title {
    margin: 0 0 var(--spacing-sm) 0;
    font-size: 1.1rem;
    color: var(--text-dark);
    cursor: pointer;
    transition: color var(--transition-normal);
}

.topic-title:hover {
    color: var(--primary-color);
}

.topic-stats {
    display: flex;
    gap: var(--spacing-md);
    font-size: 0.9rem;
    color: var(--text-light);
}

.topic-stats span {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
}

.topic-stats i {
    font-size: 0.9rem;
    color: var(--primary-color);
    transition: transform var(--transition-normal);
}

.topic-stats span:hover i {
    transform: scale(1.2);
}
.trending-topics-section {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
}

.trending-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0;
}

.header-left {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.toggle-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    color: #666;
    transition: all 0.3s ease;
}

.toggle-button:hover {
    color: #333;
}

.trending-topics-container {
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
}

.trending-topics-container.collapsed {
    max-height: 400px; /* Adjust based on your needs */
}

.trending-topics-container.expanded {
    max-height: none;
}

.show-more {
    text-align: center;
    padding: 1rem;
    background: linear-gradient(
        180deg, 
        rgba(255,255,255,0) 0%, 
        rgba(255,255,255,1) 50%
    );
}

.show-more button {
    padding: 0.5rem 1rem;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.show-more button:hover {
    background-color: #e0e0e0;
}

/* Optional: Add animation for smoother transitions */
.trending-topics-container {
    transition: all 0.3s ease-in-out;
}

.trending-topic-card {
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/*=============================================
=            Responsive Adjustments           =
=============================================*/
@media (max-width: 768px) {
    .trending-topics-section {
        padding: var(--spacing-md);
    }

    .trending-topics-container {
        grid-template-columns: 1fr;
    }

    .topic-stats {
        flex-direction: column;
        gap: var(--spacing-xs);
    }
}

@media (max-width: 480px) {
    .trending-header {
        flex-direction: column;
        gap: var(--spacing-sm);
        text-align: center;
    }

    .trending-topic-card {
        flex-direction: column;
        text-align: center;
        gap: var(--spacing-sm);
    }

    .topic-rank::after {
        display: none;
    }
}

/* Continue with Part 3... */

/*=============================================
=            Articles Section                 =
=============================================*/
/* .single-column-articles-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
    position: relative;
    min-height: 200px;
}

.single-article-row {
    background-color: var(--background-white);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-sm);
    transition: all var(--transition-normal);
    border: 1px solid var(--border-color);
    overflow: hidden;
    position: relative;
}

.single-article-row:hover {
    transform: translateY(-3px);
    box-shadow: var(--shadow-lg);
    border-color: var(--primary-light);
}

.single-article-row::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: var(--primary-color);
    transform: scaleY(0);
    transition: transform var(--transition-normal);
}

.single-article-row:hover::before {
    transform: scaleY(1);
} */

/*=============================================
=            Pagination Styles                =
=============================================*/
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-md);
    margin-top: var(--spacing-xl);
    padding: var(--spacing-md);
    background-color: var(--background-white);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-md);
    position: relative;
    overflow: hidden;
}

.pagination::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: linear-gradient(to right, var(--primary-color), var(--primary-light));
}

.page-input-container {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    position: relative;
}

.page-input {
    width: 70px;
    padding: 0.5rem;
    border: 2px solid var(--border-color);
    border-radius: var(--border-radius-sm);
    text-align: center;
    font-size: 1rem;
    transition: all var(--transition-normal);
}

.page-input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(48, 140, 132, 0.1);
}

.go-to-page-btn {
    background-color: var(--primary-color);
    color: var(--background-white);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius-sm);
    cursor: pointer;
    transition: all var(--transition-normal);
    font-weight: 500;
}

.go-to-page-btn:hover:not(:disabled) {
    background-color: var(--primary-dark);
    transform: translateY(-1px);
}

.go-to-page-btn:disabled {
    background-color: var(--border-color);
    cursor: not-allowed;
    opacity: 0.7;
}

.pagination-btn {
    background-color: var(--primary-color);
    color: var(--background-white);
    border: none;
    padding: 0.5rem 1.25rem;
    border-radius: var(--border-radius-lg);
    cursor: pointer;
    transition: all var(--transition-normal);
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
}

.pagination-btn:hover:not(:disabled) {
    background-color: var(--primary-dark);
    transform: translateY(-1px);
    box-shadow: var(--shadow-sm);
}

.pagination-btn:disabled {
    background-color: var(--border-color);
    cursor: not-allowed;
    opacity: 0.7;
}

.page-info {
    font-size: 1rem;
    color: var(--text-light);
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
}

/*=============================================
=            Loading States                   =
=============================================*/
.page-loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: var(--z-index-overlay);
    backdrop-filter: blur(5px);
    animation: fadeIn var(--transition-normal);
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid var(--border-color);
    border-top-color: var(--primary-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.single-column-articles-container.loading {
    opacity: 0.7;
    pointer-events: none;
}

.pagination-btn.loading {
    position: relative;
    overflow: hidden;
}

.pagination-btn.loading::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.2),
        transparent
    );
    animation: loading-shimmer 1.5s infinite;
}

/*=============================================
=            Error Handling                   =
=============================================*/
.error-container {
    background-color: var(--background-white);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-md);
    padding: var(--spacing-xl);
    text-align: center;
    max-width: 500px;
    margin: var(--spacing-xl) auto;
    border: 1px solid var(--error-color);
    animation: shake 0.5s ease-in-out;
}

.error-container h2 {
    color: var(--error-color);
    margin-bottom: var(--spacing-md);
    font-size: 1.5rem;
}

.error-container p {
    color: var(--text-light);
    margin-bottom: var(--spacing-lg);
    line-height: 1.5;
}

.error-container button {
    background-color: var(--error-color);
    color: var(--background-white);
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: var(--border-radius-lg);
    cursor: pointer;
    transition: all var(--transition-normal);
    font-weight: 500;
}

.error-container button:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-md);
    background-color: darken(var(--error-color), 10%);
}

/*=============================================
=            Animations                       =
=============================================*/
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes spin {
    to { transform: rotate(360deg); }
}

@keyframes loading-shimmer {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
}

@keyframes shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-10px); }
    75% { transform: translateX(10px); }
}

/*=============================================
=            Accessibility Improvements       =
=============================================*/
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

/*=============================================
=            Print Styles                     =
=============================================*/
@media print {
    .enhanced-newspaper-container {
        padding: 0;
        background: none;
    }

    .pagination,
    .search-filter-container,
    .trending-topics-section {
        display: none;
    }
}

/* No Results Styles */
.no-results {
    background-color: var(--background-white);
    border-radius: 12px;
    box-shadow: var(--shadow-md);
    padding: 2rem;
    text-align: center;
    max-width: 500px;
    margin: 2rem auto;
    border: 1px solid var(--border-color);
    animation: fadeIn 0.3s ease;
    position: relative;
    overflow: hidden;
}

.no-results::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(to right, var(--primary-color), var(--primary-light));
}

.no-results i {
    font-size: 3rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
    display: block;
    opacity: 0.7;
    transition: transform 0.3s ease;
}

.no-results:hover i {
    transform: scale(1.1);
}

.no-results h3 {
    color: var(--text-dark);
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: 600;
    position: relative;
    padding-bottom: 0.5rem;
}

.no-results h3::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 2px;
    background-color: var(--primary-color);
    border-radius: 2px;
}

.no-results p {
    color: var(--text-light);
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.no-results button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1rem;
    font-weight: 500;
    box-shadow: var(--shadow-sm);
    position: relative;
    overflow: hidden;
}

.no-results button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: width 0.6s ease, height 0.6s ease;
}

.no-results button:hover {
    background-color: var(--primary-dark);
    transform: translateY(-2px);
    box-shadow: var(--shadow-md);
}

.no-results button:hover::before {
    width: 300px;
    height: 300px;
}

.no-results button:active {
    transform: translateY(1px);
    box-shadow: var(--shadow-sm);
}

/* Animation for the component */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .no-results {
        margin: 1rem;
        padding: 1.5rem;
    }

    .no-results i {
        font-size: 2.5rem;
    }

    .no-results h3 {
        font-size: 1.25rem;
    }

    .no-results p {
        font-size: 0.95rem;
        max-width: 95%;
    }

    .no-results button {
        padding: 0.6rem 1.25rem;
        font-size: 0.95rem;
    }
}

/* Accessibility improvements */
@media (prefers-reduced-motion: reduce) {
    .no-results {
        animation: none;
    }

    .no-results button {
        transition: none;
    }

    .no-results button::before {
        display: none;
    }
}

/* High contrast mode */
@media (prefers-contrast: high) {
    .no-results {
        border: 2px solid var(--text-dark);
    }

    .no-results button {
        border: 2px solid var(--text-dark);
        box-shadow: none;
    }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
    .no-results {
        background-color: var(--text-dark);
        border-color: var(--primary-light);
    }

    .no-results h3 {
        color: var(--background-white);
    }

    .no-results p {
        color: var(--border-color);
    }

    .no-results button {
        background-color: var(--primary-light);
        color: var(--text-dark);
    }

    .no-results button:hover {
        background-color: var(--secondary-color);
    }
}


/*=============================================
=            Datetime            =
=============================================*/
/* Date Filter Styles */
.date-filter {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    padding: 1rem;
    background-color: var(--background-white);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-sm);
}

.date-filter label,
.date-filter span {
    color: var(--text-dark);
    font-weight: 800;
    font-size: 1rem;
}

.date-input {
    padding: 0.75rem 1rem;
    border: 2px solid var(--border-color);
    border-radius: var(--border-radius-md);
    font-size: 1rem;
    color: var(--text-dark);
    background-color: var(--background-white);
    transition: all var(--transition-normal);
    min-width: 200px;
}

.date-input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(48, 140, 132, 0.1);
}

/* Responsive design */
@media (max-width: 768px) {
    .date-filter {
        flex-direction: column;
        align-items: stretch;
        gap: 0.5rem;
    }

    .date-input {
        width: 100%;
    }
}