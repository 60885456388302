/* ArticleCard.css */
:root {
    --primary-color: #308C84;
    --secondary-color: #4ECDC4;
    --accent-color: #FF6B6B;
    --text-dark: #2c3e50;
    --text-light: #4a5568;
    --background-light: #f5f7fa;
    --background-white: #ffffff;
    --border-color: #e0e0e0;
    --border-radius: 16px;
    --box-shadow: 0 15px 35px rgba(0, 0, 0, 0.05);
}

/* Card Container */
.enhanced-article-card {
    display: flex;
    background: linear-gradient(145deg, var(--background-white), var(--background-light));
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    margin-bottom: 1.5rem;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.6s ease forwards;
    animation-delay: calc(var(--index) * 0.1s);
    border: 1px solid transparent;
}

.enhanced-article-card:hover {
    transform: translateY(-15px) scale(1.03);
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.1);
    border-color: var(--primary-color);
}

/* Image Container */
.article-image-container {
    position: relative;
    width: 40%;
    overflow: hidden;
}

.article-image {
    width: 100%;
    height: 355px;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.enhanced-article-card:hover .article-image {
    transform: scale(1.1);
}

/* Image Overlay */
.image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(to top, rgba(0,0,0,0.7), transparent);
    padding: 1rem;
    display: flex;
    align-items: flex-end;
    opacity: 1;
    transition: background 0.4s ease;
}

/* Category Badges */
.category-badges {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;
}

.category-badge {
    background-color: rgba(48, 140, 132, 0.2);
    color: var(--primary-color);
    padding: 0.3rem 0.6rem;
    border-radius: 20px;
    font-size: 0.7rem;
    font-weight: 600;
    transition: all 0.3s ease;
    border: 1px solid rgba(48, 140, 132, 0.3);
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.category-badge:hover {
    background-color: rgba(48, 140, 132, 0.3);
    transform: scale(1.05);
}

.category-badge.more-categories {
    background-color: rgba(48, 140, 132, 0.3);
    color: var(--text-dark);
    font-weight: bold;
}

.image-overlay .category-badge {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.image-overlay .category-badge:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

/* Card Content */
.card-content {
    width: 60%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Summary Actions */
.summary-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    flex-wrap: wrap;
    gap: 0.5rem;
}

/* Card Header */
.card-header {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.article-title {
    font-size: 1.5rem;
    color: var(--text-dark);
    line-height: 1.4;
    transition: color 0.3s ease;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.enhanced-article-card:hover .article-title {
    color: var(--primary-color);
}

/* Summary Section */
.summary-section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.article-summary {
    color: var(--text-light);
    line-height: 1.8;
    margin-bottom: 1rem;
    text-align: justify;
    flex-grow: 1;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Keyword Highlight */
.keyword-highlight {
    transition: all 0.3s ease;
    display: inline-block;
    border-radius: 4px;
}

.keyword-highlight:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

/* Expand Summary Button */
.expand-summary {
    align-self: flex-start;
    background-color: rgba(48, 140, 132, 0.1);
    color: var(--primary-color);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 600;
}

.expand-summary:hover {
    background-color: rgba(48, 140, 132, 0.2);
    transform: translateY(-2px);
}

/* Summary Statistics */
.summary-stats {
    display: flex;
    justify-content: space-between;
    background-color: rgba(48, 140, 132, 0.05);
    padding: 1rem;
    border-radius: 10px;
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stat-label {
    font-size: 0.8rem;
    color: var(--text-light);
    margin-bottom: 0.3rem;
}

.stat-value {
    font-size: 1.2rem;
    color: var(--primary-color);
    font-weight: bold;
}

/* Card Footer */
.card-footer {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

/* Article Metadata */
.article-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.meta-details {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.meta-details span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--text-light);
    font-size: 0.9rem;
}

.meta-details i {
    color: var(--primary-color);
}

.publication-date {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--text-light);
    font-size: 0.9rem;
}

/* Read More Link */
.read-more {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 600;
    transition: all 0.3s ease;
}

.read-more svg {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease;
}

.read-more:hover {
    color: var(--secondary-color);
}

.read-more:hover svg {
    transform: translateX(5px);
}

/* Animations */
@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .enhanced-article-card {
        flex-direction: column;
    }

    .article-image-container,
    .card-content {
        width: 100%;
    }

    .article-image {
        height: 250px;
    }

    .article-title {
        font-size: 1.3rem;
    }

    .summary-actions {
        flex-direction: column;
        align-items: flex-start;
    }

    .category-badges {
        margin-top: 0.5rem;
    }

    .article-meta {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }
}

/* Print Styles */
@media print {
    .enhanced-article-card {
        box-shadow: none;
        border: 1px solid var(--border-color);
    }
}