/* Container styles */
.compact-newspaper-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
}

/* Search section styles */
.compact-search {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 30px;
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.search-input-group {
    display: flex;
    gap: 12px;
}

.search-input-group input {
    flex: 1;
    padding: 12px 16px;
    border: 2px solid #eaeaea;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.search-input-group input:focus {
    border-color: #308C84;
    outline: none;
    box-shadow: 0 0 0 3px rgba(48, 140, 132, 0.1);
}

.search-input-group button {
    padding: 12px 24px;
    background-color: #308C84;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease;
}

.search-input-group button:hover {
    background-color: #266b65;
    transform: translateY(-1px);
}

/* Time range filters */
.time-range-filters {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding: 5px;
}

.time-range-btn {
    padding: 8px 16px;
    border: 2px solid #eaeaea;
    border-radius: 25px;
    background: white;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.2s ease;
    font-weight: 500;
}

.time-range-btn:hover {
    background: #f5f5f5;
    border-color: #308C84;
}

.time-range-btn.active {
    background: #308C84;
    color: white;
    border-color: #308C84;
    box-shadow: 0 2px 5px rgba(48, 140, 132, 0.2);
}

/* Article card styles */
.compact-article-card {
    padding: 20px;
    background: white;
    border-radius: 12px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.compact-article-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.compact-article-card h3 {
    margin: 0 0 12px 0;
    font-size: 1.3em;
    color: #2c3e50;
    line-height: 1.4;
}

.compact-article-card p {
    margin: 0 0 15px 0;
    color: #5f6368;
    line-height: 1.6;
    font-size: 1rem;
}

.article-metadata {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    border-top: 1px solid #eaeaea;
    font-size: 0.9rem;
}

.article-metadata span {
    color: #666;
}

.article-metadata a {
    color: #308C84;
    text-decoration: none;
    font-weight: 500;
    padding: 6px 12px;
    border-radius: 15px;
    background: rgba(48, 140, 132, 0.1);
    transition: all 0.2s ease;
}

.article-metadata a:hover {
    background: rgba(48, 140, 132, 0.2);
    color: #266b65;
}

/* Pagination styles */
.compact-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
    padding: 20px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.compact-pagination button {
    padding: 10px 20px;
    background-color: #308C84;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;
}

.compact-pagination button:hover:not(:disabled) {
    background-color: #266b65;
    transform: translateY(-1px);
}

.compact-pagination button:disabled {
    background-color: #d1d1d1;
    cursor: not-allowed;
}

.compact-pagination span {
    font-weight: 500;
    color: #2c3e50;
}

/* Enhanced transitions */
.articles-transition-enter {
    opacity: 0;
    transform: translateY(20px);
}

.articles-transition-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

.articles-transition-exit {
    opacity: 1;
}

.articles-transition-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* Responsive design */
@media (max-width: 768px) {
    .compact-newspaper-container {
        padding: 15px;
    }

    .compact-search {
        padding: 15px;
    }

    .time-range-filters {
        justify-content: center;
    }

    .time-range-btn {
        padding: 6px 12px;
        font-size: 0.85rem;
    }

    .compact-article-card {
        padding: 15px;
    }

    .compact-article-card h3 {
        font-size: 1.1em;
    }
}

@media (max-width: 480px) {
    .search-input-group {
        flex-direction: column;
    }

    .search-input-group button {
        width: 100%;
    }

    .time-range-btn {
        padding: 4px 10px;
        font-size: 0.8rem;
    }

    .article-metadata {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }
}