/* Modal.css */
:root {
    --modal-overlay-bg: rgba(0, 0, 0, 0.6);
    --modal-bg: #ffffff;
    --modal-border-radius: 16px;
    --primary-color: #308C84;
    --text-dark: #2c3e50;
    --text-light: #4a5568;
    --border-color: #e0e0e0;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--modal-overlay-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
    opacity: 0;
    animation: modalOverlayFadeIn 0.3s ease forwards;
}

.modal-container {
    background-color: var(--modal-bg);
    border-radius: var(--modal-border-radius);
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    width: 90%;
    max-width: 800px;
    transform: scale(0.9);
    animation: modalScaleUp 0.3s ease forwards;
    overflow: hidden;
}

/* Modal Sizes */
.modal-small {
    max-width: 400px;
}

.modal-medium {
    max-width: 600px;
}

.modal-large {
    max-width: 800px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid var(--border-color);
}

.modal-title {
    font-size: 1.8rem;
    color: var(--text-dark);
    margin: 0;
}

.modal-close-button {
    background: none;
    border: none;
    font-size: 2.5rem;
    color: var(--text-light);
    cursor: pointer;
    line-height: 1;
    padding: 0;
    transition: color 0.3s ease;
}

.modal-close-button:hover {
    color: var(--primary-color);
}

.modal-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 1.5rem;
    scrollbar-width: thin;
    scrollbar-color: var(--primary-color) transparent;
}

/* Custom Scrollbar */
.modal-content::-webkit-scrollbar {
    width: 8px;
}

.modal-content::-webkit-scrollbar-track {
    background: transparent;
}

.modal-content::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 20px;
    border: 2px solid transparent;
    background-clip: content-box;
}

/* Article Summary Modal Specific Styles */
.article-summary-modal .article-modal-meta {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border-color);
}

.article-modal-meta .meta-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--text-light);
    font-size: 0.9rem;
}

.article-modal-meta .meta-item i {
    color: var(--primary-color);
    font-size: 1rem;
}

.article-modal-summary {
    line-height: 1.8;
    text-align: justify;
    color: var(--text-dark);
    margin-bottom: 1.5rem;
}

.article-modal-footer {
    border-top: 1px solid var(--border-color);
    padding: 1.5rem;
    display: flex;
    justify-content: flex-end;
}

.full-article-link {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s ease;
}

.full-article-link:hover {
    color: #2a7e70;
}

.full-article-link svg {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease;
}

.full-article-link:hover svg {
    transform: translateX(5px);
}

/* Animations */
@keyframes modalOverlayFadeIn {
    to {
        opacity: 1;
    }
}

@keyframes modalScaleUp {
    to {
        transform: scale(1);
    }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .modal-container {
        width: 95%;
        max-height: 95vh;
    }

    .modal-header {
        padding: 1rem;
    }

    .modal-title {
        font-size: 1.5rem;
    }

    .modal-content {
        padding: 1rem;
    }

    .article-modal-meta {
        flex-direction: column;
        gap: 0.5rem;
    }

    .article-modal-footer {
        padding: 1rem;
    }
}

/* Keyword Highlight (if not already defined) */
.keyword-highlight {
    background-color: rgba(48, 140, 132, 0.1);
    color: var(--primary-color);
    padding: 0.1rem 0.3rem;
    border-radius: 4px;
    margin: 0 0.2rem;
    transition: all 0.3s ease;
}

.keyword-highlight:hover {
    background-color: rgba(48, 140, 132, 0.2);
}